@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.App {
  display: flex;
  
  padding: 40px;

}

.info-section {
  flex: 0 0 auto;
  /* Fixed width, change the width as needed */
  position: static;
  /* Default position */
  height: auto;
  /* Adjust height to content */
  overflow-y: visible;
  /* Default overflow */

  width: 100%;
  /* Full width on small screens */
}

.scrollable-section {
  flex: 1 1 auto;
  /* Takes up the remaining space */
  height: 100%;
  /* Adjust height to content */
  overflow-y: visible;
  /* Default overflow */

  margin-top: 20px;
  /* Add space between sections */
  
  /* Full width on small screens */
}



.social-links {
  margin-top: auto;
  /* Pushes the social links to the bottom */
  display: flex;
  justify-content: left;
}




.interest-ticker {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.interest-ticker div {
  animation: scroll-left 20s linear infinite;
}

.nav-button{
  position: 'fixed';
  bottom: 50px; 
  left: 40px; 
  zIndex: 1000;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 100;
}


@media (min-width: 900px) {
  .App {
    padding: 80px;
  }

  .info-section {
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    width: 400px;
  }

  .scrollable-section {
    margin-left: 400px;
    height: 100%;
    overflow-y: auto;
  }

  .nav-button {
    position: 'fixed';
    bottom: 50px; 
    left: 80px; 
    zIndex: 1000;
  }
}

.popover-menu {
  position: relative;
}

.popover-menu .ant-btn {
  border: none;
}